<!-- 练习档案 -->
<template>
  <div>
    <div class="file">
      <van-nav-bar
        title="练习档案"
        left-text=""
        left-arrow
        class="navbar"
        @click-left="goBack"
      />
      <div class="content">
        <div class="fileItem" @click="gotousermsg">
          <div class="fileItem-title">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/gerenxinxi.png"
              alt=""
            />
            <div>个人信息</div>
          </div>
        </div>

        <div class="fileItem" @click="togglePracticeRecords">
          <div
            class="fileItem-title"
            :class="{ 'title-active': showPracticeRecords }"
          >
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/bianjiwenzi.png"
              alt=""
            />
            <div>练习记录</div>
          </div>

          <!-- Use a transition wrapper around the practice records content -->
          <transition name="fade">
            <div class="fileItem-content" v-show="showPracticeRecords">
              <div
                class="ctxitem"
                v-if="PracticeList"
                v-for="(item, index) in PracticeList"
                :key="index"
                @click="topracticelist(item)"
              >
                <img
                  src="https://lesson.iapeap.com/images/EmotionalValue/lxjl.png"
                  alt=""
                />
                <div>{{ item.stage }} 阶练习记录</div>
              </div>
              <div class="meiyoushuju" v-if="!PracticeList">
                <img
                  src="https://lesson.iapeap.com/images/EmotionalValue/kulian.png"
                  alt=""
                />
                <div>暂无记录</div>
              </div>
            </div>
          </transition>
        </div>

        <div class="fileItem" @click="gotoachievement">
          <div class="fileItem-title">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/lianxichengguo.png"
              alt=""
            />
            <div>练习成果</div>
          </div>
        </div>

        <div class="fileItem" @click="gotorecord">
          <div class="fileItem-title">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/cepingjilu.png"
              alt=""
            />
            <div>测评记录</div>
          </div>
        </div>

        <div class="fileItem" @click="gotolike">
          <div class="fileItem-title">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/xihuandelianxi.png"
              alt=""
            />
            <div>喜欢的练习</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Practice } from "../../api/login";

export default {
  data() {
    return {
      name: "",
      showPracticeRecords: false,
      PracticeList: [],
    };
  },
  created() {
    this.getgetPractice();
  },
  methods: {
    topracticelist(item) {
      window.localStorage.setItem("stage", item.stage);
      this.$router.push({
        path: "/setout/practicelist",
        query: { stage: item.stage },
      });
    },
    togglePracticeRecords() {
      this.showPracticeRecords = !this.showPracticeRecords;
    },
    getgetPractice() {
      let data = { userId: window.localStorage.getItem("userId") };
      Practice(data).then((res) => {
        this.PracticeList = res.data;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    gotousermsg() {
      this.$router.push("/user/usermsg");
    },

    gotoachievement() {
      this.$router.push("/practice/achievement");
    },

    gotorecord() {
      this.$router.push("/evaluating/record");
    },
    gotolike() {
      this.$router.push("/practice/like");
    },
  },
};
</script>

<style lang="less">
.file {
  min-height: 100vh;
  .content {
    padding: 20px 32px;
    box-sizing: border-box;
  }
  .navbar {
    background-color: #ededed;
    .van-nav-bar__arrow {
      color: #464646;
    }
  }
  .fileItem {
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    .fileItem-title {
      cursor: pointer;
      margin-top: 10px;
      display: flex;
      align-items: center;
      line-height: 48px;
      gap: 6px;
      font-size: 16px;
      font-weight: 500;
      color: #72acf4;
      padding: 0 20px;

      img {
        width: 16px;
      }
    }
    .title-active {
      background-color: #72acf4;
      color: #fff;
      border-radius: 8px 8px 0 0;
    }
    .fileItem-content {
      padding: 10px;
      box-sizing: border-box;
      .meiyoushuju {
        text-align: center;
        color: #8a8a8a;
        img {
          width: 30%;
        }
      }

      .ctxitem {
        margin-bottom: 10px;
        height: 30px;
        font-size: 13px;
        font-weight: 400;
        color: #000025;
        line-height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 18px;
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s; /* Adjust the duration as needed */
  }
}
</style>
